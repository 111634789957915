import React from "react";
import styled from "@emotion/styled";
import { Color } from "../utils/color";
import { EnLayout } from "../components/layout/enLayout";

const EnIndexPage = () => {
  return (
    <EnLayout title="LIME" jpUrl="/" enUrl="/en/">
      <SSection>
        <p>EN PAGE</p>
      </SSection>
    </EnLayout>
  );
};

export default EnIndexPage;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
